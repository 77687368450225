<template>
    <authorization-wrapper>
        <div v-if="showModal" class="complite-registration">
            <welcome v-if="emailConfirmed" :name="`${name} ${mname}`" status="email" @click="$emit('close')"/>
            <welcome v-if="regCompleted" :name="`${name} ${mname}`" status="completed" to="/cabinet/profile"/>
        </div>
        <div v-if="!showModal" class="complite-registration">
            <h1>Паспортные данные</h1>
            <h3>Все поля обязательны для заполнения</h3>
            <div class="fcomplite-registration__form">
                <div class="complite-registration__form__body">
                    <div class="col-3">
                        <rwm-text-field
                                label="Фамилия"
                                v-model="sname"
                                readonly
                                :disabled="true"
                        />
                    </div>
                    <div class="col-3">
                        <rwm-text-field
                                label="Имя"
                                v-model="name"
                                readonly
                                :disabled="true"
                        />
                    </div>
                    <div class="col-3">
                        <rwm-text-field
                                label="Отчество"
                                v-model="mname"
                                readonly
                                :disabled="true"
                        />
                    </div>
                    <div class="col-3">
                        <rwm-date-picker label="Дата рождения" v-model="birthday" readonly :disabled="true"/>
                    </div>
                    <div class="col-3">
                        <rwm-text-field
                                label="Место рождения - город"
                                v-model="birthplace"
                                readonly
                                :disabled="true"
                        />
                    </div>
                    <div class="col-3"/>
                    <div class="col-12">
                        <rwm-text-field
                                type="address"
                                label="Адрес проживания – индекс, город, улица, дом, квартира"
                                v-model="registrationAddress"
                                readonly
                                :disabled="true"
                        />
                    </div>
                    <div class="col-12">
                        <rwm-text-field
                                label="Адрес проживания – индекс, город, улица, дом, квартира"
                                v-model="actualAddress"
                                readonly
                                :disabled="true"
                        />
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col">
                                <rwm-text-field
                                        label="Серия и номер паспорта – формат ХХХХ ХХХХХХ"
                                        :value="passport.number"
                                        @blur="validatePassport('number', $event.target.value)"
                                        :error="errors.number"
                                        v-maska="'#### ######'"
                                        :disabled="isEdit"
                                />
                            </div>
                            <div class="col-3">
                                <rwm-input-file
                                        :filename="passport.scan.file && passport.scan.file.length > 0 ? passport.scan.file[0].name : ''"
                                        title="Прикрепить скан паспорта"
                                        refer="uploadPassport"
                                        @FileChange="onFileLoad($event, 'passport')"
                                        @deleteFile="deleteItem('passport')"
                                        :error="errors.scan"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <rwm-text-field
                                label="Код подразделения – формат ХХХ-ХХХ"
                                :value="passport.department"
                                v-maska="'###-###'"
                                @blur="validatePassport('department', $event.target.value)"
                                :error="errors.department"
                                :disabled="isEdit"
                        />
                    </div>
                    <div class="col-12">
                        <rwm-autocomplete
                                label="Кем выдан паспорт"
                                v-model="passport.issue"
                                :error="errors.issue"
                                :list="issuedByList"
                                :disabled="isEdit"
                                @select="validatePassport('issue', $event)"
                                @blur="validatePassport('issue', $event.target.value)"
                        />
                    </div>
                    <div class="row">
                        <div class="col-3">
                            <rwm-date-picker
                                    label="Дата выдачи"
                                    :value="passport.issueDate"
                                    @input="validatePassport('issueDate', $event)"
                                    :error="errors.issueDate"
                                    @isError="isDateError"
                                    :disabled="isEdit"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            <rwm-autocomplete
                                    label="ИНН"
                                    :value="inn"
                                    :error="errors.inn"
                                    :list="innList"
                                    :mask="'############'"
                                    @select="findList"
                                    @blur="findList($event.target.value)"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            <rwm-text-field label="СНИЛС" v-model="snils.number" v-mask="'###-###-### ##'"
                                            @blur="_validateSnils('number')" :error="errors.snilsNumber"/>
                        </div>
                        <div class="col-3">
                            <rwm-input-file
                                    :filename="snils.scan.file && snils.scan.file.length > 0 ? snils.scan.file[0].name : ''"
                                    title="Прикрепить скан СНИЛС"
                                    refer="uploadSnils"
                                    @FileChange="onFileLoad($event, 'snils')"
                                    @deleteFile="deleteItem('snils')"
                                    :error="errors.snilsScan"
                            />
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-3">
                                <rwm-button class="complite-registration__form__body__sign" width="100%"
                                            @click="validateForm()" :disabled="timer">Подписать
                                </rwm-button>
                                <rwm-sms-checking
                                        :time="60"
                                        v-model="timering"
                                        field-width="150"
                                        @verified="codeIsVerified"
                                        @sendAgain="sendAgain()"
                                        :isShow="showChecking"
                                />
                            </div>
                            <div class="col-3"/>
                            <div class="col-3"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </authorization-wrapper>
</template>

<script>
    import AuthorizationWrapper from "../modules/AuthorizationWrapper";
    import RwmTextField from "../RWMTextField/RwmTextField";
    import RwmDatePicker from "../RWMDatePicker/RwmDatePicker";
    import RwmButton from "../RWMButton/RwmButton";
    import RwmSmsChecking from "../RWMSMSChecking/RwmSmsChecking";
    import RwmInputFile from "../RWMInputFile/RwmInputFile";
    import Welcome from "../modals/Welcome";
    import {validateParam, validateSnils} from "../../models/Validation";
    import RwmAutocomplete from "../RWMAutocomplete/RwmAutocomplete";

    export default {
        name: "CompleteRegistration",
        components: {
            RwmAutocomplete,
            Welcome,
            RwmInputFile,
            RwmSmsChecking, RwmButton, RwmDatePicker, RwmTextField, AuthorizationWrapper
        },
        props: {
            sname: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: true
            },
            mname: {
                type: String,
                required: true
            },
            birthplace: {
                type: String,
                required: true
            },
            birthday: {
                type: String,
                required: true
            },
            registrationAddress: {
                type: String,
                required: true
            },
            actualAddress: {
                type: String,
                required: true
            },
            innList: {
                type: Array,
                default: () => []
            },
            issuedByList: {
                type: Array,
                default: () => []
            },
            inn: {
                type: String,
                default: "",
                required: true
            },
            timer: {
                type: Boolean,
                default: false
            },
            showChecking: {
                type: Boolean,
                required: true
            },
            passportView: Object,
            snilsView: Object,
            showModal: {
                type: Boolean,
                default: false
            },
            emailConfirmed: {
                type: Boolean,
                default: false
            },
            regCompleted: {
                type: Boolean,
                default: false
            },
            registrationMethod: [Number, String],
        },
        computed: {
            isEdit() {
                return this.registrationMethod >= 3
            },
            timering: {
                get() {
                    return this.timer
                },
                set(val) {
                    this.$emit('endsTimer', val)
                }
            },
            passport() {
                return this.passportView
            },
            snils() {
                return this.snilsView
            }
        },
        data() {
            return {
                is: {
                    passport: true,
                    snils: true
                },
                errors: {
                    department: false,
                    issue: false,
                    issueDate: false,
                    scan: false,
                    number: false,
                    snilsNumber: false,
                    snilsScan: false,
                    inn: false
                }
            }
        },
        methods: {
            findList(input) {
                this.errors.inn = !validateParam({inn: input}, 'inn').success
                this.$emit('editDoc', {update: true, key: "inn", value: input})
            },
            validateForm() {
                Object.keys(this.passport).forEach(param => {
                    this.errors[param] = !validateParam({passport: this.passport}, param).success
                })
                let er = {}
                this.errors.snilsNumber = validateSnils(this.snils.number.replace(/-|\s/g, ''), er).code ? true : false
                this.errors.scan = !this.passport.scan.file.length > 0
                this.errors.snilsScan = !this.snils.scan.file.length > 0
                let result = false
                Object.keys(this.errors).forEach(param => {
                    if (this.errors[param]) {
                        result = true
                    }
                })
                if (!this.timer && !result) {
                    let data = {passport: this.passport, snils: this.snils}
                    this.$emit('submit', data)
                }
            },
            codeIsVerified(code) {
                this.$emit('veryfied', code)
            },
            sendAgain() {
                this.$emit('sendAgain')
            },
            isDateError(err) {
                this.errors.issueDate = err
            },
            validatePassport(param, value) {
                if (Object.keys(this.errors).includes(param)) {
                    if (param === "department" && value !== "") {
                        const reg = /\d{3}-\d{3}/
                        if (value.match(reg)) {
                            if (value !== this.passport.department) this.$emit('editDoc', {doc: "passport", key: "issue", value: ""})
                            this.$emit('editDoc', {doc: "passport", key: param, value: value})
                            this.errors[param] = false
                        } else {
                            this.errors[param] = true
                        }
                        return
                    } else if (param === "department" && value === "") {
                        this.$emit('editDoc', {doc: "passport", key: "issue", value: value})
                    }
                    this.$emit('editDoc', {doc: "passport", key: param, value: value})
                    value.length === 0 ? this.errors[param] = true : this.errors[param] = false
                }
            },
            _validateSnils(param) {
                let upperString = param.replace(/( |^)[а-яёa-z]/g, function (x) {
                    return x.toUpperCase();
                })
                if (Object.keys(this.errors).includes(`snils${upperString}`)) {
                    let er = {}
                    if (validateSnils(this.snils[param].replace(/-|\s/g, ''), er) === true) {
                        this.$emit('editDoc', {doc: "snils", key: param, value: this.snils[param]})
                        this.errors[`snils${upperString}`] = false
                    } else {
                        this.errors[`snils${upperString}`] = true
                    }
                }
            },
            onFileLoad(event, type) {
                event.preventDefault();
                this.$emit('fileChange', event.target.files[0], type)
                this.is[type] = false
            },
            deleteItem(type) {
                this[type].scan.file = []
                this[type].scan.type = 0
                this.is[type] = true
            }
        }
    }
</script>

<style scoped lang="scss">
    .complite-registration {
        flex: 1 1 auto;
        padding: 44px 42px 60px 42px;
        text-align: left;
        max-width: 1480px;
        margin: auto;
        @media screen and (min-width: 961px) {
            padding-left: 40px;
            padding-right: 40px;
        }

        & h1 {
            margin-bottom: 24px;
        }

        & h3 {
            margin-bottom: 38px;
        }

        &__form {
            margin: auto;
            padding-top: 44px;

            &__body {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                column-gap: 44px;
                margin-top: 24px;
                row-gap: 24px;

                & .row {
                    width: 100%;
                    display: flex;
                    column-gap: 44px;
                    row-gap: 24px;
                    flex-direction: column;
                    @media screen and (min-width: 961px) {
                        flex-direction: row;
                    }
                }

                & .col {
                    flex: 1 1 auto;
                }

                & .col-3 {
                    min-width: 250px;
                    flex: 1 1 calc((100% / 3) - 2rem);
                    @media screen and (min-width: 961px) {
                        flex: 0 0 calc((100% / 3) - 2rem);
                    }
                }

                & .col-12 {
                    flex: 0 1 100%;
                }

                &__sign {
                    margin-top: 20px;
                    margin-bottom: 44px;
                }
            }
        }
    }

</style>